var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", [
    _c("h1", [_vm._v(_vm._s(_vm.msg))]),
    !_vm.user
      ? _c("button", { attrs: { type: "button" }, on: { click: _vm.login } }, [
          _vm._v(" Login with Microsoft ")
        ])
      : _vm._e(),
    _vm.user
      ? _c(
          "button",
          { attrs: { type: "button" }, on: { click: _vm.callAPI } },
          [_vm._v(" Call Graph's /me API ")]
        )
      : _vm._e(),
    _vm.user
      ? _c("button", { attrs: { type: "button" }, on: { click: _vm.logout } }, [
          _vm._v("Logout")
        ])
      : _vm._e(),
    _vm.user ? _c("h3", [_vm._v("Hello " + _vm._s(_vm.user.name))]) : _vm._e(),
    _vm.userInfo
      ? _c("pre", [_vm._v(_vm._s(JSON.stringify(_vm.userInfo, null, 4)))])
      : _vm._e(),
    _vm.loginFailed ? _c("p", [_vm._v("Login unsuccessful")]) : _vm._e(),
    _vm.apiCallFailed
      ? _c("p", [_vm._v("Graph API call unsuccessful")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }